import React from "react";
import "./index.css";
import "./utils/types/moduleOverrides";
import Provider from "./utils/provider";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Launchpad from "./components/Launchpad";
import S from "./App.styled";
import ReactGA from "react-ga";

function App() {
  const TRACKING_ID = "UA-XXXXX-X"; // replace your tracking code.
  ReactGA.initialize(TRACKING_ID);

  return (
    <Provider>
      <S.App>
        <Header />
        <Launchpad />
        <Footer />
      </S.App>
    </Provider>
  );
}

export default App;
