import { Button, styled } from "@mui/material";

namespace S {
  export const Header = styled("div")(({ theme }) => ({
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    backgroundColor: theme.palette.secondary.main,

    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    },
    [theme.breakpoints.down(425)]: {
      padding: "1.25rem 0.5rem",
    },
  }));

  export const ContentWrapper = styled("div")(({ theme }) => ({
    maxWidth: "1440px",
    margin: "0 auto",
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
    },
  }));

  export const YearnTextLogo = styled("img")(({ theme }) => ({
    paddingTop: "3px",
    height: "25px",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "23px",
    },
    [theme.breakpoints.down(425)]: {
      height: "20px",
    },
  }));

  export const NavLinkBtn = styled("a")(({ theme }) => ({
    color: theme.palette.text.light[400],
    textDecoration: "none",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "10px",
    },
  }));

  export const ILDOBtn = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down(425)]: {
      fontSize: "10px",
    },
  }));
}

export default S;
