import { FC } from "react";
import { Box } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Linkedin from "../../../../../assets/images/sm-linkedin.png";
import Telegram from "../../../../../assets/images/sm-telegram.png";
import Twitter from "../../../../../assets/images/sm-twitter.png";
import Play from "../../../../../assets/images/play.png";
import Web from "../../../../../assets/images/WebIcon.svg";
import Docs from "../../../../../assets/images/DocsIcon.svg";
import { IProjectCards } from "../../../../../utils/types/components";

import S from "./styled";

const TopContent: FC<IProjectCards["ProjectCard"]["TopContent"]> = ({
  tempData,
  distributedAmounts,
}) => {
  return (
    <S.IntroWrapper>
      <S.TopLeftContainer>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            paddingBottom: "1rem",
          }}
        >
          <S.ProjectImage src={tempData.image} alt="Project Image"/>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <S.InfoTitle>{tempData?.name}</S.InfoTitle>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              <S.CustomChip label={`${tempData?.saleType}`} />
              <S.Status
                tint={tempData.status === "Ongoing" ? "green" : "yellow"}
              >
                {tempData.status}
              </S.Status>
            </Box>
          </Box>
        </Box>
        <S.InfoText>{tempData.about}</S.InfoText>
        <S.MediaProfilesWrapper>
          <a href={tempData.web} target="_blank" rel="noreferrer">
            <S.MediaProfileBtn src={Web} alt="Dashboard"/>
          </a>
          <a href={tempData.twitter} target="_blank" rel="noreferrer">
            <S.MediaProfileBtn src={Twitter} alt="Twitter"/>
          </a>
          <a href={tempData.linkedIn} target="_blank" rel="noreferrer">
            <S.MediaProfileBtn src={Linkedin} alt="LinkedIn" />
          </a>
          <a href={tempData.telegram} target="_blank" rel="noreferrer">
            <S.MediaProfileBtn src={Telegram} alt="Telegram"/>
          </a>
          <a href={tempData.docs} target="_blank" rel="noreferrer">
            <S.MediaProfileBtn src={Docs} alt="Docs" />
          </a>
        </S.MediaProfilesWrapper>
      </S.TopLeftContainer>
      <S.TopRightContainer>
        <S.CustomCarousel
          showArrows={false}
          showIndicators={true}
          stopOnHover={true}
          showThumbs={false}
          showStatus={false}
          autoPlay
          infiniteLoop
          swipeable
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            return (
              <S.Dot
                selected={isSelected}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          {/* <S.CarousalSubContainer sx={{ height: "7rem" }}>
            <S.CarousalHeaderText>COMPLETED</S.CarousalHeaderText>
            <S.CarousalSubheaderText>PERCENTAGE</S.CarousalSubheaderText>
            <S.CarousalValue>
              {tempData?.saleType === "Presale-1" ? "60%" : "3%"}
            </S.CarousalValue>
          </S.CarousalSubContainer> */}
          <S.CarousalSubContainer sx={{ height: "7rem" }}>
            <S.CarousalHeaderText>TOTAL</S.CarousalHeaderText>
            <S.CarousalSubheaderText>DEPOSITED</S.CarousalSubheaderText>
            <S.CarousalValue>
              ${distributedAmounts?.earningDistributed ?? 0 }
            </S.CarousalValue>
          </S.CarousalSubContainer>
          <S.CarousalSubContainer sx={{ height: "7rem" }}>
            <S.CarousalHeaderText>WINNINGS </S.CarousalHeaderText>
            <S.CarousalSubheaderText>DISTRIBUTED</S.CarousalSubheaderText>
            <S.CarousalValue>
              ${parseInt(distributedAmounts?.winningDistributed ?? 0)}
            </S.CarousalValue>
          </S.CarousalSubContainer>
          <S.CarousalSubContainer sx={{ height: "7rem" }}>
            <S.CarousalHeaderText>REFERRAL</S.CarousalHeaderText>
            <S.CarousalSubheaderText>DISTRIBUTED</S.CarousalSubheaderText>
            <S.CarousalValue>
              ${parseInt(distributedAmounts?.referralAmount ?? 0)}
            </S.CarousalValue>
          </S.CarousalSubContainer>
          <S.CarousalSubContainer sx={{ height: "7rem" }}>
            <S.CarousalHeaderText>TOTAL</S.CarousalHeaderText>
            <S.CarousalSubheaderText>USERS</S.CarousalSubheaderText>
            <S.CarousalValue>{distributedAmounts?.totalUser ?? 0}</S.CarousalValue>
          </S.CarousalSubContainer>
          <S.CarousalSubContainer sx={{ height: "7rem" }}>
            <S.CarousalHeaderText>COMPLETED 1K</S.CarousalHeaderText>
            <S.CarousalSubheaderText>DRAWS</S.CarousalSubheaderText>
            <S.CarousalValue>
              {distributedAmounts?.completedDraws ?? 0}
            </S.CarousalValue>
          </S.CarousalSubContainer>
          <S.CarousalSubContainer sx={{ height: "7rem" }}>
            <S.CarousalHeaderText>COMPLETED 10K</S.CarousalHeaderText>
            <S.CarousalSubheaderText>DRAWS</S.CarousalSubheaderText>
            <S.CarousalValue>
              {distributedAmounts?.completedTenKDraws ?? 0}
            </S.CarousalValue>
          </S.CarousalSubContainer>
        </S.CustomCarousel>
        <S.BtnsContainer>
          <a
            href="https://www.youtube.com/watch?v=iubKwVTMmQo"
            target="_blank"
            rel="noreferrer"
          >
            <S.PlayImg src={Play} alt="Play" />
          </a>
          <S.CustomBtn
            onClick={() => window.open("https://app.yearntogether.com/")}
          >
            Participate
          </S.CustomBtn>
        </S.BtnsContainer>
      </S.TopRightContainer>
    </S.IntroWrapper>
  );
};

export default TopContent;
