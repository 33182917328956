import YearnLogo from "../assets/images/yearn-logo.png";

export const PLATFORM_URL: any = {
  80001: `${process.env.REACT_APP_SUBGRAPH_ENDPOINT}`,
  56: `${process.env.REACT_APP_BSC_SUBGRAPH_LOTTO_ENDPOINT}`,
};

export const TOKEN: { [id: number]: string | undefined } = {
  80001: process.env.REACT_APP_TOKEN_ADDRESS,
  56: process.env.REACT_APP_BSC_YTG,
};

export const CURRENT_CHAIN_ID: number = Number(
  process.env.REACT_APP_CURRENT_CHAINID
);

let currentTokenAddress = TOKEN[CURRENT_CHAIN_ID];

export const PROJECT_CARD_DATA = [
  {
    tokenAddress: currentTokenAddress,
    name: "YearnTogether",
    symbol: "YEARN",
    decimals: 18,
    network: "BNB Smart Chain",
    totalSupply: "600,000,000 YEARN",
    presaleStarts: "2023.05.29 12:25 (UTC)",
    unsoldTokens: "Burn",
    presaleRate: "0.05 USD / YEARN",
    listingPrice: "0.1 USD / YEARN",
    elitePass: "55 USDT (1000 YEARN)",
    minBuy: "1 Elite Pass",
    maxBuy: "100 Elite Passes",
    presaleCount: "120,000,000",
    vesting: "20% at TGE, 20% for the next 4 months",
    image: YearnLogo,
    fullLink: "https://app.yearntogether.com/",
    about:
      "Revolutionize fundraising with our decentralized platform powered by web3.",
    saleType: "Presale-1",
    status: "Ongoing",
    web: "https://yearntogether.com/",
    twitter: "https://twitter.com/yearn_together",
    linkedIn: "https://linkedin.com/company/YearnTogether",
    telegram: "https://t.me/YearntogetherGlobalchat",
    docs: "https://docs.yearntogether.com/",
  },
  // {
  //   tokenAddress: currentTokenAddress,
  //   name: "YearnTogether",
  //   symbol: "YEARN",
  //   decimals: 18,
  //   network: "BNB Smart Chain",
  //   totalSupply: "600,000,000 YEARN",
  //   presaleStarts: "TBA",
  //   unsoldTokens: "Burn",
  //   presaleRate: "0.07 USD / YEARN",
  //   listingPrice: "0.1 USD / YEARN",
  //   elitePass: "55 USDT (750 YEARN)",
  //   minBuy: "1 Elite Pass",
  //   maxBuy: "100 Elite Passes",
  //   presaleCount: "90,000,000",
  //   vesting: "20% at TGE, 20% for the next 4 months",
  //   image: YearnLogo,
  //   fullLink: "https://app.yearntogether.com/",
  //   about:
  //     "Revolutionize fundraising with our decentralized platform powered by web3.",
  //   saleType: "Presale-2",
  //   status: "Upcoming",
  //   web: "https://yearntogether.com/",
  //   twitter: "https://twitter.com/yearn_together",
  //   linkedIn: "https://linkedin.com/company/YearnTogether",
  //   telegram: "https://t.me/YearntogetherGlobalchat",
  //   docs: "https://docs.yearntogether.com/",
  // },
];
