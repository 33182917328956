import { FC, useState } from "react";
import S from "./styled";
import CopyIcon from "../../../../../assets/images/copy_white.svg";
import { IProjectCards } from "../../../../../utils/types/components";
import { getShortenAddress } from "../../../../../utils/helpers/app";
import { Tooltip, useMediaQuery } from "@mui/material";

const MiddleContent: FC<IProjectCards["ProjectCard"]["MiddleContent"]> = ({
  tempData,
}) => {
  // states
  const [tooltipTimer, setTooltipTimer] = useState<boolean>(false);

  const mediumView = useMediaQuery("(max-width:900px)");

  const handleCopy = (address: string) => {
    navigator.clipboard.writeText(address);
    handleTooltip();
  };

  const handleTooltip = () => {
    setTooltipTimer(true);
    setTimeout(() => {
      setTooltipTimer(false);
    }, 2000);
  };

  return (
    <S.MiddleContent>
      <S.InfoTile
        sx={{
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <S.InfoTileLabel sx={{ width: "20%" }}>Token Address:</S.InfoTileLabel>
        <S.InfoTileValue
          sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
        >
          <S.CustomLink
            href={`https://bscscan.com/token/${tempData.tokenAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            {mediumView
              ? getShortenAddress(tempData.tokenAddress)
              : tempData.tokenAddress}
          </S.CustomLink>
          <Tooltip title={tooltipTimer ? "copied!" : ""} arrow>
            <S.CustomCopyIcon
              src={CopyIcon}
              alt="Copy"
              onClick={() => handleCopy(tempData.tokenAddress)}
            />
          </Tooltip>
        </S.InfoTileValue>
      </S.InfoTile>

      <S.DetailsWrapper>
        <S.InfoWrapper>
          <S.InfoTile>
            <S.InfoTileLabel>Name:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.name}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Symbol:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.symbol}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Decimals:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.decimals}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Network:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.network}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Total Supply:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.totalSupply}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Presale Starts:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.presaleStarts}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Unsold Tokens:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.unsoldTokens}</S.InfoTileValue>
          </S.InfoTile>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.InfoTile>
            <S.InfoTileLabel>Presale Rate:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.presaleRate}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Listing Rate:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.listingPrice}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>1 Elite Pass:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData?.elitePass}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Min buy:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.minBuy}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Max Buy:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.maxBuy}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>{tempData.saleType}:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.presaleCount}</S.InfoTileValue>
          </S.InfoTile>
          <S.InfoTile>
            <S.InfoTileLabel>Vesting:</S.InfoTileLabel>
            <S.InfoTileValue>{tempData.vesting}</S.InfoTileValue>
          </S.InfoTile>
        </S.InfoWrapper>
      </S.DetailsWrapper>
    </S.MiddleContent>
  );
};

export default MiddleContent;
