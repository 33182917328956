import { Box, useTheme } from "@mui/material";
import { FC, useMemo } from "react";

const SVGGradientSupport: FC = () => {
	const gradients = useTheme().palette.background.gradient;
	const gradientKeys = useMemo(() => Object.keys(gradients), [gradients]);

	return (
		<Box style={{ maxHeight: 0 }}>
			{gradientKeys.map((k) => (
				<svg style={{ height: 0, width: 0 }} key={k}>
					<defs>
						<linearGradient id={k} gradientTransform={"rotate(90)"}>
							{gradients[k as keyof typeof gradients].checkpoints.map(([color, offset]) => (
								<stop offset={offset} stopColor={color} key={color + offset} />
							))}
						</linearGradient>
					</defs>
				</svg>
			))}
		</Box>
	);
};

export default SVGGradientSupport;
