import { styled } from "@mui/material";

namespace S {
	export const Launchpad = styled("div")(({ theme }) => ({
		padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
		background: theme.palette.secondary.main,
		[theme.breakpoints.down("md")]: {
			padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
			backgroundImage: "none",
		},
		position: "relative",
		minHeight: "calc(100vh - 135px)",
		[theme.breakpoints.down(425)]: {
			padding: "1.25rem 0.5rem",
		},
	}));

	export const ContentWrapper = styled("div")(({ theme }) => ({
		maxWidth: "1440px",
		margin: "0 auto",
		display: "flex",
		flexDirection: "column",
		gap: "40px",
	}));
}

export default S;
