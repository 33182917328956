import React from "react";
import YearnTextLogo from "../../assets/images/yearn-text-logo.png";
import S from "./styled";
import Linkedin from "../../assets/images/sm-linkedin.png";
import Telegram from "../../assets/images/sm-telegram.png";
import Twitter from "../../assets/images/sm-twitter.png";

const Footer = () => {
  return (
    <S.Footer>
      <S.ContentWrapper>
        <S.TopRowWrapper>
          <S.YearnTextLogo src={YearnTextLogo} alt="YearnLogo" />
          <S.CopyrightText>
            &copy; Copyright 2023. All rights reserved.
          </S.CopyrightText>
          <S.NavLinkStack>
            <S.NavLink
              href="https://docs.yearntogether.com/yearntogether/frequently-asked-questions"
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </S.NavLink>
            <S.NavLink
              href="https://docs.yearntogether.com/terms-and-conditions/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              Terms Of Service
            </S.NavLink>
            <S.NavLink
              href="https://docs.yearntogether.com/terms-and-conditions/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </S.NavLink>
            |
            <a
              href="https://linkedin.com/company/YearnTogether"
              target="_blank"
              rel="noreferrer"
            >
              <S.MediaProfile src={Linkedin} alt="LinkedIn"/>
            </a>
            <a
              href="https://t.me/YearntogetherGlobalchat"
              target="_blank"
              rel="noreferrer"
            >
              <S.MediaProfile src={Telegram} alt="telegram" />
            </a>
            <a
              href="https://twitter.com/yearn_together"
              target="_blank"
              rel="noreferrer"
            >
              <S.MediaProfile src={Twitter} alt="Twitter"/>
            </a>
          </S.NavLinkStack>
        </S.TopRowWrapper>
      </S.ContentWrapper>
    </S.Footer>
  );
};

export default Footer;
