import { Box, Button, Chip, Typography, styled } from "@mui/material";
import { addAlpha } from "../../../../../utils/helpers/app";
import { Carousel } from "react-responsive-carousel";

namespace S {
  export const IntroWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down(768)]: {
      gap: "1rem",
    },
  }));

  export const ProjectImage = styled("img")(({ theme }) => ({
    height: "60px",
    width: "60px",
  }));

  export const ProjectSubContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
  }));

  export const ProjectName = styled("h4")(({ theme }) => ({
    fontSize: "20px",
    fontWeight: 600,
    color: theme.palette.text.light[400],
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
  }));

  export const ProjectLink = styled("a")(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    textDecoration: "none",
    marginTop: "1rem",
    [theme.breakpoints.down(425)]: {
      fontSize: "12px",
    },
  }));

  export const MediaProfilesWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    margin: "1.5rem 0 0 0",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(425)]: {
      gap: "10px",
    },
  }));

  export const MediaProfileBtn = styled("img")(({ theme }) => ({
    height: "20px",
    width: "auto",
  }));

  export const ActionsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "30px",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(425)]: {
      gap: "10px",
    },
  }));

  export const TopLeftContainer = styled("div")(({ theme }) => ({
    width: "50%",
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  }));

  export const TopRightContainer = styled("div")(({ theme }) => ({
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down(768)]: {
      alignItems: "center",
      width: "100%",
    },
  }));

  export const Status = styled("p")<{ tint: "green" | "yellow" }>(
    ({ theme, tint }) => ({
      padding: "0.3rem 0.5rem 0.3rem 1.5rem",
      borderRadius: "1rem",
      border: `2px solid ${theme.palette.text.light[400]}`,
      fontSize: "12px",
      fontWeight: 400,
      color:
        tint === "green"
          ? theme.palette.success.main
          : theme.palette.warning.main,
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        width: "8px",
        height: "8px",
        backgroundColor:
          tint === "green"
            ? theme.palette.success.main
            : theme.palette.warning.main,
        top: "50%",
        left: theme.spacing(2),
        transform: "translateY(-50%)",
        borderRadius: theme.shape.radius.full,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        padding: `${theme.spacing(1)} ${theme.spacing(5)} ${theme.spacing(
          1
        )} ${theme.spacing(8)}`,
      },
    })
  );

  export const PlayImg = styled("img")(({ theme }) => ({
    height: "46px",
    width: "46px",
    marginTop: "10px",
    cursor: "pointer",
  }));

  export const BtnsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
  }));

  export const CustomBtn = styled(Button)(({ theme }) => ({
    height: "30px",
    fontSize: "1rem",
  }));

  export const InfoWrapper = styled("div")(({ theme }) => ({
    // minWidth: "15rem",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      alignItems: "center",
    },
  }));

  export const InfoTitle = styled("h6")(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.text.special.label,
  }));

  export const CarousalSubContainer = styled(Box)(({ theme }) => ({
    padding: "1rem",
    borderRadius: "0.625rem",
  }));

  export const CarousalHeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "0.8rem",
    fontWeight: 500,
    color: theme.palette.text.light[400],
    textAlign: "left",
  }));

  export const CarousalSubheaderText = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.text.light[400],
    textAlign: "left",
  }));

  export const CarousalValue = styled(Typography)(({ theme }) => ({
    fontSize: "1.75rem",
    fontWeight: 500,
    color: theme.palette.text.special.label,
  }));

  export const InfoText = styled("p")(({ theme }) => ({
    fontSize: "14px",
    color: theme.palette.text.light[400],
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  }));

  export const CustomChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.text.light[400],
    background: addAlpha("#595959", 0.3),
    height: "2rem",
    width: "6rem",
  }));

  export const CustomCarousel = styled(Carousel, {
    shouldForwardProp: (prop) => prop !== "isMarginActive",
  })(({ isMarginActive = false }: { isMarginActive?: boolean }) => ({
    ".control-dots": {
      margin: isMarginActive ? "0.2rem 0" : "0 !important",
      display: "flex !important",
      justifyContent: "center !important",
      gap: "0.5rem !important",
      borderRadius: "0.5rem",
    },
    height: "8rem",
    width: "15rem",
    border: "1px solid white",
    borderRadius: "0.625rem",
  }));

  export const Dot = styled("li", {
    shouldForwardProp: (prop) => prop !== "selected",
  })(({ selected }: { selected: boolean }) => ({ theme }) => ({
    width: selected ? "1.875rem" : "0.5rem",
    height: "0.5rem",
    borderRadius: selected ? "0.375rem" : "50%",
    transitionDuration: "0.2s",
    listStyle: "none",
    background: "#1967FC",
    // backgroundColor: selected
    //   ? theme.palette.custom.primary.main
    //   : theme.palette.custom.secondary.secondary_1,
    "&:hover": {
      cursor: "pointer",
    },
  }));

  export const ProgressText = styled("p")(({ theme }) => ({
    fontSize: "18px",
    fontWeight: 700,
    color: "transparent",
    background: theme.palette.text.gradient.primary,
    backgroundClip: "text",
    webkitBackgroundClip: "text",
  }));
}

export default S;
