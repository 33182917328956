import { Stack, styled } from "@mui/material";
import { addAlpha } from "../../../../utils/helpers/app";

namespace S {
  const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    height: "1px",
    backgroundColor: theme.palette.text.light[400],
  }));

  export const ProjectCard = styled(Stack)(({ theme }) => ({
    width: "100%", // controlled by grid in ProjectCards
    maxWidth: "800px",
    padding: `${theme.spacing(8)}`,
    // backgroundColor: theme.palette.background.dark[400],
    borderRadius: theme.shape.radius[3],
    flexDirection: "column",
    border: `3px solid ${theme.palette.text.light[400]}`,
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(4)}`,
      borderRadius: theme.shape.radius[2],
      gap: "20px",
    },
  }));

  ProjectCard.defaultProps = {
    divider: <Divider />,
  };
}

export default S;
