import { createTheme, ThemeOptions } from "@mui/material";
import { addAlpha } from "../helpers/app";

export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#1967FC",
    },
    secondary: {
      main: "#110C24",
    },
    background: {
      dark: {
        "400": "#1C1632",
      },
      light: {
        "400": "#ffffff",
      },
      gradient: {
        primary: {
          value: "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
          angle: "337.35deg",
          checkpoints: [
            ["#EB00FF", "7.34%"],
            ["#E9B80C", "91.96%"],
          ],
        },
      },
    },
    text: {
      dark: {
        "200": "#595959",
        "400": "#16151A",
      },
      light: {
        "400": "#ffffff",
      },
      special: {
        label: "#EFB614",
      },
      gradient: {
        primary: "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
      },
    },
    success: {
      main: "#0AE93B",
    },
    warning: {
      main: "#EFB614",
    },
  },
  typography: {
    fontFamily: "'Poppins', 'sans-serif'",
  },
  shape: {
    radius: {
      1: "8px",
      2: "20px",
      3: "70px",
      full: "100%",
    },
  },
  spacing: (factor: number) => `${0.3125 * factor}rem`,
  components: {
    MuiButton: {
      styleOverrides: {
        textPrimary: ({ theme }) => {
          return {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.light[400],
            padding: ".4rem 1.2rem",
            borderRadius: theme.shape.radius[1],
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            minWidth: "min-content",

            "&:hover": {
              backgroundColor: addAlpha(theme.palette.primary.main, 0.75),
            },
          };
        },
      },
    },
  },
});
