import React from "react";
import Filters from "./Filters";
import Info from "./Info";
import ProjectCards from "./ProjectCards";
import S from "./styled";

const Launchpad = () => {
  return (
    <S.Launchpad>
      <S.ContentWrapper>
        <Info />
        <ProjectCards />
      </S.ContentWrapper>
    </S.Launchpad>
  );
};

export default Launchpad;
