import { styled } from "@mui/material";

namespace S {
  export const App = styled("div")(() => ({
    // styles to keep footer at bottom even when content is small
    // display: "grid",
    // gridTemplateRows: "auto 1fr auto",
    // minHeight: "100vh",
  }));
}

export default S;
