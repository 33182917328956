import React from "react";
import YearnTextLogo from "../../assets/images/yearn-text-logo.png";
import S from "./styled";

const Header = () => {
  return (
    <S.Header>
      <S.ContentWrapper>
        <S.YearnTextLogo src={YearnTextLogo} alt="YearnTogether Logo" />
        <S.NavLinkBtn
          href="https://docs.yearntogether.com/welcome/introduction"
          target="_blank"
          rel="noreferrer"
        >
          Docs
        </S.NavLinkBtn>
        <S.NavLinkBtn
          href="https://affiliate.yearntogether.com/"
          target="_blank"
          rel="noreferrer"
        >
          Affiliate Program
        </S.NavLinkBtn>
        {/* <S.NavLinkBtn
          href="https://launchpad.yearntogether.com/"
          target="_blank"
          rel="noreferrer"
        >
          Launchpad
        </S.NavLinkBtn> */}
        {/* <S.ILDOBtn>Request IDO</S.ILDOBtn> */}
      </S.ContentWrapper>
    </S.Header>
  );
};

export default Header;
