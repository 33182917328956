import { styled } from "@mui/material";

namespace S {
  // TODO: change this to grid
  export const ProjectCards = styled("div")(({ theme }) => ({
    display: "grid",
    // gridTemplateColumns: "repeat(auto-fit, minmax(550px, .7fr))",
    gap: "40px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    },
  }));
}

export default S;
