import Web3 from "web3";

// returns hex value with opacity added
export const addAlpha = (hex: string, opacity: number) => {
  let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return hex + _opacity.toString(16).toUpperCase();
};

// returns short address
export const getShortenAddress = (address: string) => {
  return `${address.slice(0, 10)}....${address.slice(-10)}`;
};

export const getRoundAmount = (amount: string) => {
  return parseFloat(Web3.utils.fromWei(amount, "ether")).toFixed(2);
};
