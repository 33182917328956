import { Stack, styled } from "@mui/material";

namespace S {
  export const Footer = styled("div")(({ theme }) => ({
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    },
  }));

  export const ContentWrapper = styled("div")(({ theme }) => ({
    maxWidth: "1440px",
    margin: "0 auto",
  }));

  export const TopRowWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: "30px",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      marginBottom: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(425)]: {
      gap: "10px",
      marginBottom: "10px",
    },
  }));

  export const BottomRowWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    marginTop: "10px",
  }));

  export const YearnTextLogo = styled("img")(({ theme }) => ({
    paddingTop: "3px",
    height: "30px",
    [theme.breakpoints.down("sm")]: {
      height: "40px",
    },
    [theme.breakpoints.down(425)]: {
      height: "30px",
    },
  }));

  export const CopyrightText = styled("p")(({ theme }) => ({
    color: theme.palette.text.light[400],
    fontWeight: 500,
    whiteSpace: "nowrap",
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "0.6rem",
    },
  }));

  const Divider = styled("div")(({ theme }) => ({
    width: "1px",
    height: "15px",
  }));

  export const NavLinkStack = styled(Stack)(({ theme }) => ({
    gap: "10px",
    alignItems: "center",
    color: theme.palette.text.light[400],
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(425)]: {
      gap: "10px",
    },
  }));

  NavLinkStack.defaultProps = {
    direction: "row",
    divider: <Divider />,
  };

  export const NavLink = styled("a")(({ theme }) => ({
    color: theme.palette.text.light[400],
    fontWeight: 500,
    whiteSpace: "nowrap",
    cursor: "pointer",
    fontSize: "0.8rem",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "0.6rem",
    },
  }));

  export const MediaProfile = styled("img")(({ theme }) => ({
    height: "15px",
  }));
}

export default S;
