import { FC } from "react";
import { IProjectCards } from "../../../utils/types/components";
import { PROJECT_CARD_DATA } from "../../../utils";
import ProjectCard from "./ProjectCard";

import S from "./styled";

const ProjectCards: FC<IProjectCards["index"]> = () => {
  return (
    <S.ProjectCards>
      {PROJECT_CARD_DATA.map((data: any) => {
        return <ProjectCard tempData={data} />;
      })}
    </S.ProjectCards>
  );
};

export default ProjectCards;
