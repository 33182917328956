import { styled } from "@mui/material";
import { addAlpha } from "../../../../../utils/helpers/app";

namespace S {
  export const MiddleContent = styled("div")(({ theme }) => ({
    // display: "flex",
    // gap: "30px",
    // flexDirection: "column",
    // display: "grid",
    // gridTemplateColumns: "1fr 1fr",
  }));

  export const DetailsWrapper = styled("div")(({ theme }) => ({
    // display: "flex",
    // justifyContent: "space-between",
    // gap: "30px",
    // [theme.breakpoints.down("sm")]: {
    //   flexDirection: "column",
    //   gap: "20px",
    // },
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  }));

  export const InfoWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: "20px",
    },
    [theme.breakpoints.down(425)]: {
      gap: "10px",
    },
  }));

  export const InfoTile = styled("div")(({ theme }) => ({
    display: "flex",
    // gap: "10px",
  }));

  export const InfoTileLabel = styled("p")(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.special.label,
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "12px",
    },
  }));

  export const InfoTileValue = styled("p")(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.light[400],
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "12px",
    },
  }));

  export const CustomLink = styled("a")(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.background.light[400],
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "12px",
    },
  }));

  export const CustomCopyIcon = styled("img")(({ theme }) => ({
    height: "1rem",
    cursor: "pointer",
  }));

  export const ProgressWrapper = styled("div")(({ theme }) => ({
    // flexShrink: "1",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
    // justifyContent: "center",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  }));

  export const ProgressText = styled("p")(({ theme }) => ({
    fontSize: "28px",
    fontWeight: 700,
    color: "transparent",
    background: theme.palette.text.gradient.primary,
    backgroundClip: "text",
    webkitBackgroundClip: "text",
  }));

  export const StatisticsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      gap: "15px",
    },
    [theme.breakpoints.down(425)]: {
      gap: "10px",
    },
  }));

  export const StatisticWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    padding: `${theme.spacing(4)}`,
    width: "33%",
    flexShrink: "1",
    borderRadius: theme.shape.radius[2],
    border: `2px solid ${theme.palette.text.light[400]}`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)}`,
    },
  }));

  export const StatisticValue = styled("p")(({ theme }) => ({
    fontSize: "26px",
    fontWeight: 600,
    color: "transparent",
    background: theme.palette.text.gradient.primary,
    backgroundClip: "text",
    webkitBackgroundClip: "text",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "18px",
    },
  }));

  export const StatisticLabel = styled("p")(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 600,
    color: theme.palette.text.light[400],
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "8px",
    },
  }));

  export const ActionsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "30px",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(425)]: {
      gap: "10px",
    },
  }));

  export const Status = styled("p")<{ tint: "green" | "yellow" }>(
    ({ theme, tint }) => ({
      padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(
        3
      )} ${theme.spacing(8)}`,
      borderRadius: theme.shape.radius[2],
      border: `2px solid ${theme.palette.text.light[400]}`,
      fontSize: "18px",
      fontWeight: 600,
      color:
        tint === "green"
          ? theme.palette.success.main
          : theme.palette.warning.main,
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        width: "12px",
        height: "12px",
        backgroundColor:
          tint === "green"
            ? theme.palette.success.main
            : theme.palette.warning.main,
        top: "50%",
        left: theme.spacing(4),
        transform: "translateY(-50%)",
        borderRadius: theme.shape.radius.full,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        padding: `${theme.spacing(1)} ${theme.spacing(5)} ${theme.spacing(
          1
        )} ${theme.spacing(8)}`,
      },
    })
  );

  export const MediaProfilesWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: "30px",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(425)]: {
      gap: "10px",
    },
  }));

  export const MediaProfileBtn = styled("img")(({ theme }) => ({
    height: "20px",
    width: "20px",
  }));

  export const LikeProjectBtn = styled("img")(({ theme }) => ({
    height: "20px",
    width: "auto",
  }));
}

export default S;
