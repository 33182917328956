import { FC, useState, useEffect } from "react";

import { IProjectCards } from "../../../../utils/types/components";
import { getDistributedAmount } from "../../../../services/subgraph/query";
import { graphQlClient } from "../../../../utils/provider";
import { getRoundAmount } from "../../../../utils/helpers/app";
import MiddleContent from "./MiddleContent";
import TopContent from "./TopContent";

import S from "./styled";

const ProjectCard: FC<IProjectCards["ProjectCard"]["index"]> = ({
  tempData,
}) => {
  const [distributedAmounts, setDistributedAmounts] = useState<any>(false);

  useEffect(() => {
    getDistributedDatas();
  }, []);

  const getDistributedDatas = async () => {
    const distributedAmounts: any = await graphQlClient.request(
      getDistributedAmount
    );

    if (distributedAmounts.platformStatistics.length) {
      const platformStatistics = distributedAmounts.platformStatistics[0];
      const modifiedDistributedAmounts = {
        winningDistributed: getRoundAmount(
          platformStatistics.totalWinningAmount
        ),
        earningDistributed: getRoundAmount(
          platformStatistics.totalDepsitAmount
        ),
        referralAmount: getRoundAmount(platformStatistics.totalReferralAmount),
        totalUser: platformStatistics.totalUser,
        totalPaidUser: platformStatistics.totalPaidUser,
        currentDraw: platformStatistics.currentDraw,
        completedDraws: platformStatistics.completedDraw,
        completedTenKDraws: platformStatistics.completed10kDraw,
      };
      setDistributedAmounts(modifiedDistributedAmounts);
    }
  };

  return (
    <S.ProjectCard>
      <TopContent tempData={tempData} distributedAmounts={distributedAmounts} />
      <MiddleContent
        tempData={tempData}
        distributedAmounts={distributedAmounts}
      />
    </S.ProjectCard>
  );
};

export default ProjectCard;
