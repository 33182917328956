import { ThemeProvider } from "@mui/material";
import React, { FC } from "react";
import { GraphQLClient } from "graphql-request";
import SVGGradientSupport from "../gradients";
import { theme } from "../theme";
import { CURRENT_CHAIN_ID, PLATFORM_URL } from "..";

export const graphQlClient = new GraphQLClient(PLATFORM_URL[CURRENT_CHAIN_ID]);

const Provider: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        {children}
        <SVGGradientSupport />
      </ThemeProvider>
    </>
  );
};

export default Provider;
