import { gql } from "graphql-request";

//Distributed
export const getDistributedAmount = gql`
  query platformStatistics {
    platformStatistics {
      completed10kDraw
      completedDraw
      currentDraw
      id
      totalDepsitAmount
      totalPaidUser
      totalReferralAmount
      totalUser
      totalWinningAmount
    }
  }
`;
