import {
  Button,
  Menu,
  MenuItem,
  Select,
  alpha,
  styled,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Input,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { addAlpha } from "../../../utils/helpers/app";
import { Search } from "@mui/icons-material";

namespace S {
  export const Info = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    position: "relative",
  }));

  export const InfoTitleWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    gap: "0.5rem",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2.5rem",
    },
  }));

  export const RightContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    right: "0",
    top: "0",
    display: "flex",
    alignItems: "center",
    height: "40px",
    gap: "15px",
  }));

  export const InfoTitle = styled("h1")<{ tint?: "normal" | "themed" }>(
    ({ theme, tint = "normal" }) => ({
      fontSize: "30px",
      fontWeight: 600,
      color:
        tint === "themed"
          ? theme.palette.primary.main
          : theme.palette.text.light[400],
    })
  );

  export const InfoText = styled("p")(({ theme }) => ({
    fontSize: "15px",
    color: theme.palette.text.light[400],
    lineHeight: 1.8,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "15px",
    },
  }));

  export const FilterSelectInputItem = styled(MenuItem)(({ theme }) => ({
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
  }));

  export const FilterLabel = styled("h6")(({ theme }) => ({
    fontSize: "13px",
    color: theme.palette.text.light[400],
    fontWeight: "400",
  }));

  export const FilterWrapper = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    flexGrow: "1",
  }));

  export const CustomMenu = styled(Menu)(({ theme }) => ({
    "& .MuiMenu-paper": {
      backgroundColor: alpha(theme.palette.common.white, 0.8),
      border: "2px solid white",
      color: "black",
    },
  }));

  export const MoreVertButton = styled(Button)(() => ({
    background: "transparent",
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  }));

  export const FilterSelectInput = styled(Select)(({ theme }) => ({
    height: "3rem",
    fontSize: "16px",
    color: theme.palette.text.light[400],
    minWidth: "unset",
    width: "100%",
    borderRadius: theme.shape.radius[1],
    border: `1px solid ${theme.palette.text.light[400]}`,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(228, 219, 233, 0.25)",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(228, 219, 233, 0.25)",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(228, 219, 233, 0.25)",
    },
    ".MuiSvgIcon-root ": {
      fill: "white !important",
    },
  }));

  export const ButtonWrapper = styled(Button)(({ theme }) => ({
    minWidth: "unset",
    height: "100%",
    aspectRatio: "1",
    padding: "0",
    borderRadius: theme.shape.radius.full,
    zIndex: "2",
  }));

  export const FiltersPopover:any = styled(Popover)(({ theme }) => ({
    ".MuiPopover-paper": {
      borderRadius: theme.shape.radius[2],
    },
  }));

  export const FiltersAccordion = styled(Accordion)(({ theme }) => ({
    width: "400px",
    padding: "10px",
  }));
  FiltersAccordion.defaultProps = {
    disableGutters: true,
  };
  export const FiltersAccordionTop = styled(AccordionSummary)(
    ({ theme }) => ({})
  );
  export const FiltersAccordionBottom = styled(AccordionDetails)(
    ({ theme }) => ({})
  );

  export const FiltersAccordionLabel = styled("h3")(({ theme }) => ({
    color: theme.palette.text.dark[400],
    fontSize: "16px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    marginRight: "20px",
    lineHeight: "0",
    width: "40%",
  }));

  export const FiltersAccordionValue = styled("p")(({ theme }) => ({
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    lineHeight: "0",
    width: "60%",
    fontWeight: "500",
  }));

  export const FiltersAccordionSelectItem = styled("div", {
    shouldForwardProp: (p) => p !== "isSelected",
  })<{ isSelected: boolean }>(({ theme, isSelected }) => ({
    padding: "12px 30px",
    margin: "2px 0",
    borderRadius: theme.shape.radius[1],
    ...(isSelected
      ? {
          backgroundColor:
            addAlpha(theme.palette.primary.main, 0.2) + " !important",
        }
      : {}),
    "&:hover": {
      backgroundColor: grey[200],
    },
  }));

  export const SearchInputBox = styled("div", {
    shouldForwardProp: (p) => p !== "inputFocused",
  })<{ inputFocused: boolean }>(({ theme, inputFocused }) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "20px",
    height: "100%",
    width: "auto",
    overflow: "hidden",
    minWidth: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: inputFocused ? "8px" : "0px",
    transition: "padding .2s",
    backgroundColor: addAlpha(theme.palette.primary.main, 0.4),
    zIndex: "2",
  }));

  export const SearchInputIcon = styled(Search)(({ theme }) => ({
    color: "white",
    fontSize: "30px",
  }));

  export const SearchInput = styled(Input, {
    shouldForwardProp: (p) => p !== "isFocused",
  })<{ isFocused: boolean }>(({ theme, isFocused }) => ({
    height: "100%",
    transition: "width .6s",
    paddingLeft: isFocused ? "10px" : "0px",
    width: isFocused ? "350px" : "0",
    "&::before, &::after": {
      border: "0px !important",
    },
    color: theme.palette.text.light[400],
  }));
}

export default S;
