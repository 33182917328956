import React, { useRef, useState } from "react";
import { MoreVert, ExpandMore } from "@mui/icons-material";
import { AccordionDetails } from "@mui/material";
import filterIcon from "../../../assets/images/filterIcon.svg";

import S from "./styled";

const Info = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [expandedFilter, setExpandedFilter] = useState<
    "search" | "sort" | false
  >(false);
  const [filterBy, setFilterBy] = useState("Progress");
  const [sortBy, setSortBy] = useState("Tokens");
  const inputRef = useRef<HTMLInputElement>(null);
  const filterAnchor = useRef<HTMLButtonElement>(null);
  const [inputFocused, setInputFocused] = useState(false);

  const handleChange =
    (panel: "search" | "sort") =>
    (e: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedFilter(isExpanded ? panel : false);
    };

  return (
    <S.Info>
      <S.RightContainer>
        <S.SearchInputBox
          onClick={() => {
            setInputFocused(true);
            inputRef.current?.focus();
          }}
          inputFocused={inputFocused}
        >
          <S.SearchInputIcon />
          <S.SearchInput
            inputRef={inputRef}
            onBlur={() => setInputFocused(!!inputRef.current?.value)}
            isFocused={inputFocused}
            placeholder="Enter token name or token symbol"
          />
        </S.SearchInputBox>
        <S.ButtonWrapper
          aria-describedby="filters"
          variant="contained"
          onClick={() => setFiltersOpen(true)}
          ref={filterAnchor}
        >
          <img src={filterIcon} alt="Filter icon" />
        </S.ButtonWrapper>
      </S.RightContainer>

      <S.InfoTitleWrapper>
        <S.InfoTitle>Ongoing</S.InfoTitle>
        <S.InfoTitle tint="themed">Projects</S.InfoTitle>
      </S.InfoTitleWrapper>
      <S.InfoText>
        Welcome to the launch of the world's first-ever gaming-enabled IDO!
      </S.InfoText>
      <S.FiltersPopover
        id={"filters"}
        open={filtersOpen}
        anchorEl={filterAnchor.current}
        onClose={() => setFiltersOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{ backdrop: { invisible: false } }}
      >
        <S.FiltersAccordion
          expanded={expandedFilter === "search"}
          onChange={handleChange("search")}
        >
          <S.FiltersAccordionTop
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <S.FiltersAccordionLabel>Filter By:</S.FiltersAccordionLabel>
            <S.FiltersAccordionValue>{filterBy}</S.FiltersAccordionValue>
          </S.FiltersAccordionTop>
          <AccordionDetails>
            <S.FiltersAccordionSelectItem
              onClick={() => setFilterBy("Progress")}
              isSelected={filterBy === "Progress"}
            >
              Progress
            </S.FiltersAccordionSelectItem>
            <S.FiltersAccordionSelectItem
              onClick={() => setFilterBy("Investment")}
              isSelected={filterBy === "Investment"}
            >
              Investment
            </S.FiltersAccordionSelectItem>
            <S.FiltersAccordionSelectItem
              onClick={() => setFilterBy("Risk")}
              isSelected={filterBy === "Risk"}
            >
              Risk
            </S.FiltersAccordionSelectItem>
          </AccordionDetails>
        </S.FiltersAccordion>
        <S.FiltersAccordion
          expanded={expandedFilter === "sort"}
          onChange={handleChange("sort")}
        >
          <S.FiltersAccordionTop
            expandIcon={<ExpandMore />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <S.FiltersAccordionLabel>Sort By:</S.FiltersAccordionLabel>
            <S.FiltersAccordionValue>{sortBy}</S.FiltersAccordionValue>
          </S.FiltersAccordionTop>
          <AccordionDetails>
            <S.FiltersAccordionSelectItem
              onClick={() => setSortBy("Tokens")}
              isSelected={sortBy === "Tokens"}
            >
              Tokens
            </S.FiltersAccordionSelectItem>
            <S.FiltersAccordionSelectItem
              onClick={() => setSortBy("USDC")}
              isSelected={sortBy === "USDC"}
            >
              USDC
            </S.FiltersAccordionSelectItem>
            <S.FiltersAccordionSelectItem
              onClick={() => setSortBy("Percentage")}
              isSelected={sortBy === "Percentage"}
            >
              Percentage
            </S.FiltersAccordionSelectItem>
            <S.FiltersAccordionSelectItem
              onClick={() => setSortBy("Registration")}
              isSelected={sortBy === "Registration"}
            >
              Registration
            </S.FiltersAccordionSelectItem>
          </AccordionDetails>
        </S.FiltersAccordion>
      </S.FiltersPopover>
    </S.Info>
  );
};

export default Info;
